'use strict'

import Tagify from '@yaireo/tagify'
import Swal from 'sweetalert2'
import Sortable from 'sortablejs'

/**
 * Form class contains features specific to the registration form.
 *
 * @version 0.1.0
 */
export default class Form {

	/**
	 * Class constructor
	 */
	constructor () {
		this.init()
	}

	/**
	 * Init the class by calling applicable init methods
	 *
	 * @return {Object}
	 */
	init () {

		// Call individual init methods
		this.initDoubleSubmissionPrevention()
		this.initCreateRow()
		this.initRemoveRow()
		this.initFormLabels()
		this.initDisableAnotherInput()
		this.initToggleRequired()
		this.initToggleVisibility()
		this.initFilePreview()
		this.initImageRemove()
		this.initDuetDatepicker()
		this.initTagify()
		this.initPopulateForm()
		this.initRequireAtLeastOne()
		this.initValidateValues()
		this.initFormValidate()
		this.initFormSaver()
		this.initFormSaveAlert()
		this.initSortableRows()

		// Dispatch custom event when init is done
		document.dispatchEvent(
			new CustomEvent('registration-form-init-done', {
				bubbles: true,
				cancelable: true,
			})
		)

		return this
	}

	/**
	 * Initialize feature that prevents double submissions
	 */
	initDoubleSubmissionPrevention () {
		document.addEventListener('submit', event => {
			const form = event.target
			if (!form.classList.contains('registration-form')) return
			if (form.classList.contains('registration-form--in-progress')) {
				event.preventDefault()
				return
			}
			form.classList.add('registration-form--in-progress')
		})
	}

	/**
	 * Initialize feature that creates new form rows from a template
	 */
	initCreateRow () {
		document.addEventListener('click', event => {

			const button = event.target.closest('.js-registration-form-create-row')
			if (!button) return

			event.preventDefault()

			const template = button.closest('.js-rows').querySelector('template')
			const newNode = template.content.cloneNode(true)

			// generate unique group ID connecting new row(s)
			const groupID = [
				(new Date()).getTime(),
				Math.random().toString(36).substring(2, 9),
				Array.from(newNode.querySelectorAll('input, select, textarea'))
					.map(el => el.getAttribute('name'))
					.filter(Boolean)
					.join('|'),
			].join('-')

			const newNodeChildren = newNode.children
			for (let newChild of newNodeChildren) {
				newChild.classList.add('js-row')
				newChild.setAttribute('data-group-id', groupID)
			}

			button.parentNode.insertBefore(newNode, button)

			// find current group number
			let groupNum = 0
			let groupFound = false
			let previousGroup = null
			const groups = button.closest('.js-rows').querySelectorAll('[data-group-id]')
			groups.forEach(group => {
				if (group.parentElement.closest('[data-group-num]')) {
					return
				}
				if (group.getAttribute('data-group-id') == groupID) {
					groupFound = true
				}
				if (!groupFound && group.getAttribute('data-group-id') != previousGroup) {
					groupNum++
				}
				if (!group.hasAttribute('data-group-num')) {
					group.setAttribute('data-group-num', groupNum)
				}
				previousGroup = group.getAttribute('data-group-id')
			})

			// replace __GROUP_NUM__ with current group number
			document.querySelectorAll('[data-group-id="' + groupID + '"]').forEach(group => {
				let closestGroupWithNum = group.closest('[data-group-num]')
				if (closestGroupWithNum) {
					let currentGroupNum = closestGroupWithNum.getAttribute('data-group-num')
					group.querySelectorAll('[name*="__GROUP_NUM__"]').forEach(el => {
						el.setAttribute('name', el.getAttribute('name').replace(/__GROUP_NUM__/g, currentGroupNum))
						el.setAttribute('data-group-num', currentGroupNum)
					})
				}
			})

			const maxRows = button.getAttribute('data-max-rows')
			if (maxRows) {
				const rows = button.closest('.js-rows').querySelectorAll('.js-row')
				if (rows.length >= maxRows) {
					button.setAttribute('hidden', '')
				}
			}

			if (button.hasAttribute('data-auto-click')) {
				const removeButton = document.querySelector('.js-registration-form-remove-row[data-group-id="' + groupID + '"], [data-group-id="' + groupID + '"] .js-registration-form-remove-row')
				if (removeButton) {
					if (removeButton.parentElement.classList.contains('registration-form__group-footer')) {
						// if remove button is in group footer, hide entire group footer to avoid gap
						removeButton.parentElement.hidden = true
					} else if (removeButton.parentElement.classList.contains('js-rows')) {
						// if remove button is direct parent of row container (.js-rows) or group footer, remove to avoid gap
						removeButton.remove()
					} else {
						// add hidden class so that button is not visible but still takes up space
						removeButton.classList.add('registration-form__button--hidden')
						removeButton.disabled = true
						removeButton.ariaHidden = true
					}
				}
				button.removeAttribute('data-auto-click')
			} else if (button.hasAttribute('data-no-focus')) {
				button.removeAttribute('data-no-focus')
			} else {
				const input = button.previousElementSibling.querySelector('select:not([disabled]), input:not([type="hidden"])')
				if (input) input.focus()
			}

			document.querySelectorAll('[data-group-id="' + groupID + '"]').forEach(el => {
				this.autoClickCreateRow(el)
			})

			const sortableRows = button.closest('.js-sortable-rows')
			if (sortableRows) {
				this.initSortableRows(button.closest('.js-sortable-rows'))
			}

			this.initFormLabels()
		})

		this.autoClickCreateRow(document)
	}

	/**
	 * Look for buttons with data-min-rows option in provided context
	 */
	autoClickCreateRow (context) {
		context.querySelectorAll('.js-registration-form-create-row').forEach(button => {

			const minRows = button.getAttribute('data-min-rows')
			if (!minRows) return

			const rows = button.closest('.js-rows').querySelectorAll('.js-row')
			if (rows.length >= minRows) return

			for (let i = rows.length; i < minRows; i++) {
				button.setAttribute('data-auto-click', true)
				button.click()
			}
		})
	}

	/**
	 * Initialize feature that removes form rows
	 */
	initRemoveRow () {
		document.addEventListener('click', event => {

			const button = event.target.closest('.js-registration-form-remove-row')
			if (!button) return

			event.preventDefault()

			const rows = button.closest('.js-rows')
			const row = button.closest('.js-row')
			if (!row) return

			document.querySelectorAll('[data-group-id="' + row.getAttribute('data-group-id') + '"]').forEach(el => {
				el.remove()
			})

			this.initSortableRows(rows)

			const createButton = rows.querySelector('.js-registration-form-create-row')
			if (!createButton) return

			const maxRows = createButton.getAttribute('data-max-rows')
			if (maxRows && rows.querySelectorAll('.js-row').length < maxRows) {
				createButton.removeAttribute('hidden')
				createButton.focus()
			}
		})
	}

	/**
	 * Init feature that makes it possible to sort rows
	 */
	initSortableRows (context = document) {
		if (!context) return
		const allRows = context.classList && context.classList.contains('js-sortable-rows') ? [context] : context.querySelectorAll('.js-sortable-rows')
		allRows.forEach(rows => {
			if (rows._sortable) {
				rows._sortable.destroy()
				rows._sortable = null
			}
			if (rows.querySelectorAll('.js-row').length < 2) {
				rows.querySelectorAll('.js-sortable-rows-has-handle').forEach(row => {
					row.classList.remove('js-sortable-rows-has-handle')
					const handle = row.querySelector('.js-sortable-rows-handle')
					if (handle) handle.remove()
				})
				return
			}
			// get handle from template
			let sortableHandle = document.getElementById('js-sortable-rows-handle').content.cloneNode(true).querySelector('.js-sortable-rows-handle')
			rows.querySelectorAll('.js-row').forEach(row => {
				// add handle to each row
				if (row.classList.contains('js-sortable-rows-has-handle')) return
				let handle = sortableHandle.cloneNode(true)
				row.insertBefore(handle, row.firstChild)
				row.classList.add('js-sortable-rows-has-handle')
			})
			rows._sortable = new Sortable(rows, {
				animation: 150,
				handle: '.js-sortable-rows-handle',
				onEnd: event => {
					event.item.closest('.js-rows').querySelectorAll('.js-row').forEach((row, index) => {
						const removeButton = row.querySelector('.js-registration-form-remove-row')
						if (!removeButton) return
						// if this is first item, hide remove button
						if (index === 0) {
							removeButton.classList.add('registration-form__button--hidden')
							removeButton.disabled = true
							if (removeButton.parentElement.classList.contains('registration-form__group-footer')) {
								// if remove button is in group footer, hide entire group footer to avoid gap
								removeButton.parentElement.hidden = true
							}
						} else {
							removeButton.classList.remove('registration-form__button--hidden')
							removeButton.disabled = false
							if (removeButton.parentElement.classList.contains('registration-form__group-footer')) {
								// if remove button is in group footer, show entire group footer
								removeButton.parentElement.hidden = false
							}
						}
					})
				},
			})
		})
	}

	/**
	 * Initialize feature that connects labels to form inputs
	 */
	initFormLabels () {
		document.querySelectorAll('.registration-form__label:not([for])').forEach(label => {

			if (label.querySelector('input, select, textarea')) return

			const input = label.nextElementSibling
			if (!input || input.nodeName !== 'INPUT' && input.nodeName !== 'SELECT') return

			if (!input.getAttribute('id')) {
				input.setAttribute('id', [
					'input',
					input.getAttribute('name').replace(/[\[\]]/g, '-').replace(/-+/g, '-').replace(/^-/, '').replace(/-$/, '').toLowerCase(),
					(new Date()).getTime(),
					Math.random().toString(36).substring(2, 9),
				].join('-'))
			}

			label.setAttribute('for', input.getAttribute('id'))
		})
	}

	/**
	 * Initialize feature that disables another input when an input is checked
	 */
	initDisableAnotherInput () {
		document.addEventListener('change', event => {

			const input = event.target.closest('.js-registration-form-disable-another')
			if (!input) return

			const selectors = input.getAttribute('data-disable').split(',')
			selectors.forEach(selector => {

				const targets = document.querySelectorAll(selector.trim())
				if (!targets.length) return

				targets.forEach(target => {
					if (input.checked && !target.hasAttribute('data-locked')) {
						target.checked = false
						target.setAttribute('disabled', '')
						return
					}

					const checkedInput = document.querySelector('.js-registration-form-disable-another:checked[data-disable="' + input.getAttribute('data-disable') + '"]')
					if (!checkedInput && !target.hasAttribute('data-locked')) target.removeAttribute('disabled')
				})
			})
		})
	}

	/**
	 * Initialize feature that toggles required attribute of another input when input is checked
	 */
	initToggleRequired () {

		document.addEventListener('change', event => {

			const input = event.target.closest('.js-registration-form-toggle-required')
			if (!input) return

			const selectors = input.getAttribute('data-require').split(',')
			selectors.forEach(selector => {

				const targets = document.querySelectorAll(selector.trim())
				if (!targets.length) return

				targets.forEach(target => {

					if (input.checked) {
						target.classList.add('registration-form__field--required')
						target.querySelector('input, textarea').required = true
						return
					}

					const checkedInput = document.querySelector('.js-registration-form-toggle-required:checked[data-require="' + input.getAttribute('data-require') + '"]')
					if (!checkedInput) {
						target.classList.remove('registration-form__field--required')
						target.querySelector('input, textarea').required = false
					}
				})
			})
		})
	}

	/**
	 * Initialize feature that toggles visibility of another input when input value changes
	 */
	initToggleVisibility () {
		document.addEventListener('change', event => {

			const input = event.target.closest('.js-registration-form-toggle-visibility')
			if (!input) return

			const suffixes = input.getAttribute('data-suffixes')
				? input.getAttribute('data-suffixes').split(',')
				: ['']

			suffixes.forEach(suffix => {
				let shouldShow = this.checkIfShouldShow(input, suffix)
				const showIfAny = input.getAttribute('data-show-if-any' + suffix) == 1

				const selectors = input.getAttribute('data-target' + suffix).split(',')
				selectors.forEach(selector => {

					let context = document

					// if selector starts with ":context", it is limited to the current context
					if (selector.trim().match(/^:context /)) {
						context = input.closest('.js-registration-form-visibility-context')
						selector = selector.trim().replace(/^:context /, '')
					}

					const targets = context.querySelectorAll(selector.trim())
					if (targets.length) {

						targets.forEach(target => {

							if (!shouldShow && !showIfAny) {
								target.setAttribute('hidden', '')
								return
							}

							context.querySelectorAll('.js-registration-form-toggle-visibility[data-target' + suffix + '="' + input.getAttribute('data-target' + suffix) + '"]').forEach(visibilityInput => {
								if (showIfAny && shouldShow || !shouldShow && !showIfAny) return
								shouldShow = this.checkIfShouldShow(visibilityInput, suffix)
							})

							if (!shouldShow) {
								if (showIfAny) {
									target.setAttribute('hidden', '')
								}
								return
							}

							target.removeAttribute('hidden')
						})
					}
				})
			})
		})

		document.querySelectorAll('.js-registration-form-toggle-visibility').forEach(input => {
			input.dispatchEvent(new Event('change', {
				bubbles: true,
				cancelable: true,
			}))
		})
	}

	/**
	 * Check if input should be shown based on the value of another input
	 *
	 * @param {Element} input
	 * @param {String} suffix
	 * @return {Boolean}
	 */
	checkIfShouldShow (input, suffix = '') {
		const input_value = input.nodeName == 'SELECT'
			? (
				input.selectedIndex && input.options[input.selectedIndex] && input.options[input.selectedIndex].hasAttribute('data-value')
					? input.options[input.selectedIndex].getAttribute('data-value')
					: input.value
			)
			: input.hasAttribute('data-value')
				? input.getAttribute('data-value')
				: input.value
		return input.nodeName == 'SELECT'
			? (
				input.getAttribute('data-show' + suffix) == 1
					? input.getAttribute('data-show-if-value' + suffix) && input_value == input.getAttribute('data-show-if-value' + suffix) || !input.getAttribute('data-show-if-value' + suffix) && input_value != ''
					: input.getAttribute('data-show-if-value' + suffix) && input_value != input.getAttribute('data-show-if-value' + suffix) || !input.getAttribute('data-show-if-value' + suffix) && input_value == ''
			)
			: input.checked && input.getAttribute('data-show' + suffix) == 1 || !input.checked && input.getAttribute('data-show' + suffix) == 0
	}

	/**
	 * Initialize feature that shows file preview
	 */
	initFilePreview () {
		document.addEventListener('change', event => {

			if (!window.FileReader) return

			const context = event.target.closest('.js-registration-form-file-preview')
			if (!context) return

			const preview = context.querySelector('.registration-form__file-preview')
			if (!preview) return

			const file = event.target.files ? event.target.files[0] : null
			if (!file) return

			const removeButton = preview.querySelector('.js-registration-form-image-remove')

			preview.innerHTML = ''
			preview.nextElementSibling.value = ''

			if (file.type.indexOf('image') === 0) {
				const reader = new FileReader()
				reader.onload = readerEvent => {
					const img = document.createElement('img')
					img.src = readerEvent.target.result
					img.alt = ''
					img.classList.add('registration-form__file-preview-image')
					img.classList.add('registration-form__file-preview-image--temp')
					preview.appendChild(img)
					if (removeButton) {
						preview.appendChild(removeButton)
						removeButton.removeAttribute('hidden')
					}
				}
				reader.readAsDataURL(file)
			}
		})
	}

	/**
	 * Initialize feature that removes image(s)
	 */
	initImageRemove () {
		document.querySelectorAll('.js-registration-form-image-remove').forEach(button => {

			const preview = button.closest('.js-registration-form-file-preview')
			if (!preview) return

			button.addEventListener('click', event => {
				event.preventDefault()

				const img = button.previousElementSibling
				if (img) {
					if (img.classList.contains('registration-form__file-preview-image--remove')) {
						img.classList.remove('registration-form__file-preview-image--remove')
						button.closest('.registration-form__file-preview').nextElementSibling.value = ''
					} else {
						if (img.classList.contains('registration-form__file-preview-image--temp')) {
							img.remove()
							button.closest('.js-registration-form-file-preview').querySelector('input[type="file"]').value = ''
							button.setAttribute('hidden', '')
						} else {
							img.classList.add('registration-form__file-preview-image--remove')
							button.closest('.registration-form__file-preview').nextElementSibling.value = '1'
						}
					}
				}
			})
		})
	}

	/**
	 * Initialize Duet Datepicker
	 */
	initDuetDatepicker () {
		if (!duetDatepickerLocalization) return
		document.querySelectorAll('duet-date-picker').forEach(picker => {

			picker.dateAdapter = {
				parse (value = '', createDate) {
					const matches = value.match(/^(\d{1,2})\.(\d{1,2})\.(\d{4})$/)
					if (matches) {
						return createDate(matches[3], matches[2], matches[1])
					}
				},
				format (date) {
					return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
				},
			}

			picker.localization = duetDatepickerLocalization
		})
	}

	/**
	 * Initialize Tagify
	 */
	initTagify () {
		if (!Tagify) return
		document.querySelectorAll('.tagify').forEach(input => {
			if (input.classList.contains('tagify--outside')) {
				let tagifySettings = {
					dropdown: {
						position: 'input',
						enabled: 0, // always opens dropdown when input gets focus
						appendTarget: input.parentElement,
					},
				}
				if (input.nextElementSibling && input.nextElementSibling.classList.contains('tagify-whitelist')) {
					tagifySettings.whitelist = JSON.parse(input.nextElementSibling.innerText)
					tagifySettings.enforceWhitelist = true
				}
				input._tagify = new Tagify(input, tagifySettings)
				return
			}
			input._tagify = new Tagify(input)
		})
	}

	/**
	 * Initialize feature that sets textarea height automatically based on content
	 */
	initTextareaAutoHeight () {
		document.querySelectorAll('.registration-form__textarea').forEach(textarea => {
			textarea.addEventListener('keyup', () => {
				textarea.style.height = 'auto'
				textarea.style.height = textarea.scrollHeight + 'px'
				textarea.style.overflowY = 'hidden'
			})
			textarea.dispatchEvent(new Event('keyup'))
		})
	}

	/**
	 * Initialize feature that populates form from predefined data
	 */
	initPopulateForm () {
		const form = document.querySelector('.registration-form')
		if (!form) return

		if (window.registrationFormData) {
			this.populateForm(form, window.registrationFormData)
		}

		window.setTimeout(() => {
			form.classList.remove('registration-form--loading')
			this.initTextareaAutoHeight()
		}, 500)
	}

	/**
	 * Populate form data
	 *
	 * @param {Element} form
	 * @param {Object} data
	 */
	populateForm (form, data) {
		const keys = Object.keys(data)
		if (keys.length) {

			// console.log(data)

			keys.forEach(key => {
				const input = form.querySelector('[data-name="' + key + '"]') || form.querySelector('[name="' + key + '"]')
				if (!input) {
					return
				}
				this.populateInput(form, input, data, key)
			})
		}
	}

	/**
	 * Populate input with data
	 *
	 * @param {Element} form
	 * @param {Element} input
	 * @param {Object} data
	 * @param {String} key
	 * @param {Number} defaultIndex
	 */
	populateInput (form, input, data, key, defaultIndex = null) {

		if (input.nodeName == 'BUTTON') {

			// make sure that we have enough rows to populate
			const minRows = parseInt(input.getAttribute('data-min-rows') || 0)
			const existingRows = input.closest('.js-rows').querySelectorAll('.js-row')
			if (Math.max(minRows, existingRows.length) < (data[key] ? data[key].length : 0)) {
				for (let i = minRows; i < data[key].length; i++) {
					input.setAttribute('data-no-focus', '')
					input.click()
				}
			}

			if (!data[key]) return

			data[key].forEach((value, index) => {
				Object.keys(value).forEach(valueProp => {

					if (valueProp.match('\\[\\]')) {
						value = value[valueProp].split(/\r?\n/)
						valueProp = valueProp.replace(/\[\]/g, '')
						const childInputs = form.querySelectorAll('[data-name="' + key + '[' + valueProp + ']"]')
						const childInput = childInputs.length > index ? childInputs[index] : null
						if (childInput) {
							const childData = {
								[key]: [],
							}
							value.forEach((childValue, childIndex) => {
								childData[key][childIndex] = {
									[valueProp]: childValue,
								}
							})
							this.populateInput(form, childInput, childData, key, index)
						}
						return
					}

					const context = input.closest('.js-rows')
					const inputIndex = defaultIndex === null ? index : defaultIndex

					let childInput = context.querySelector('[name="' + key + '[' + valueProp + '][' + inputIndex + ']"]')
					if (!childInput) {
						let childInputs = context.querySelectorAll('[name="' + key + '[' + valueProp + '][' + inputIndex + '][]"]')
						if (childInputs.length > index) {
							childInput = childInputs[index]
						}
						if (!childInput) {
							childInputs = context.querySelectorAll('[name="' + key + '[' + valueProp + '][]"]')
							if (childInputs.length > index) {
								childInput = childInputs[index]
							}
							if (!childInput) {
								childInput = context.querySelector('[name="' + key + '[' + inputIndex + ']"]')
								if (!childInput) {
									let childInputs = context.querySelectorAll('[name="' + key + '[]"]')
									if (childInputs.length > index) {
										childInput = childInputs[index]
									}
								}
							}
						}
					}

					if (childInput) {
						let childInputValue = childInput.type == 'checkbox' ? childInput.checked : childInput.value
						if (childInput.type == 'checkbox') {
							childInput.checked = value[valueProp] == 1
						} else {
							childInput.value = value[valueProp] === null ? '' : value[valueProp]
							if (childInput._tagify) {
								childInput._tagify.loadOriginalValues()
							}
						}
						if (childInputValue != childInput.type == 'checkbox' ? childInput.checked : childInput.value) {
							childInput.dispatchEvent(new Event('change', {
								bubbles: true,
								cancelable: true,
							}))
						}
					}
				})
			})

			return
		}

		if (input.type == 'checkbox') {
			let inputValue = input.checked
			input.checked = data[key] == 1
			if (inputValue != input.checked) {
				input.dispatchEvent(new Event('change', {
					bubbles: true,
					cancelable: true,
				}))
			}

			return
		}

		// files are skipped, at least for now
		if (input.type == 'file') {
			return
		}

		if (input.nodeName === 'DIV') {

			if (data[key] === null) return

			const data_key = typeof data[key].forEach === 'function' ? data[key] : [data[key]]

			data_key.forEach((value, index) => {
				Object.keys(value).forEach(valueProp => {
					let internalInput = input.querySelector('[name="' + key + '[' + valueProp + ']')
					if (!internalInput) {
						internalInput = input.querySelector('[name="' + key + '[]"][value="' + value[valueProp] + '"]')
					}
					if (internalInput) {
						let internalInputValue = internalInput.type == 'checkbox' ? internalInput.checked : internalInput.value
						if (internalInput.type == 'checkbox') {
							internalInput.checked = true
						} else {
							internalInput.value = value[valueProp] === null ? '' : value[valueProp]
							if (internalInput._tagify) {
								internalInput._tagify.loadOriginalValues()
							}
						}
						if (internalInputValue != internalInput.type == 'checkbox' ? internalInput.checked : internalInput.value) {
							internalInput.dispatchEvent(new Event('change', {
								bubbles: true,
								cancelable: true,
							}))
						}
					}
				})
			})

			return
		}

		// default behaviours (for text inputs, selects, etc.)
		let inputValue = input.value
		input.value = typeof data[key] === 'object'
			? (
				data[key] === null ? '' : JSON.stringify(data[key])
			)
			: data[key]
		if (input._tagify) {
			input._tagify.loadOriginalValues()
		}
		if (inputValue != input.value) {
			input.dispatchEvent(new Event('change', {
				bubbles: true,
				cancelable: true,
			}))
		}
	}

	/**
	 * Initialize feature that validates form
	 */
	initFormValidate () {

		const form = document.querySelector('.registration-form')
		if (!form) return

		form.setAttribute('novalidate', '')

		document.addEventListener('submit', event => {

			const form = event.target.closest('.registration-form')
			if (!form) return

			event.preventDefault()

			// note: this is required so that we don't get bounced back to the submit button in case there are validation errors
			if (!document.activeElement || document.activeElement === form.querySelector('button[type="submit"]')) {
				form.querySelector('button[type="submit"]').blur()
			}

			const formIsValid = this.validateForm(form)

			if (!formIsValid) {
				form.classList.remove('registration-form--in-progress')
				form.classList.remove('registration-form--saving')
				form.classList.add('registration-form--error')
				return
			}

			form.classList.remove('registration-form--error')

			if (form.hasAttribute('data-no-submit')) {
				form.dispatchEvent(new CustomEvent('registration-form-validate-done', {
					bubbles: true,
					cancelable: true,
				}))
			} else {
				form.submit()
			}
		})
	}

	/**
	 * Initialize feature that saves form data to local storage
	 */
	initFormSaver () {
		document.addEventListener('click', event => {

			const saver = event.target.closest('.registration-saver')
			if (!saver) return

			event.preventDefault()

			const form = saver.previousElementSibling
			if (!form || !form.classList.contains('registration-form')) return

			// note: this is required so that we don't get bounced back to the submit button in case there are validation errors
			if (!document.activeElement || document.activeElement === saver.querySelector('button')) {
				saver.querySelector('button').blur()
			}

			// prevent double submission
			if (saver.classList.contains('registration-saver--in-progress')) return

			form.classList.add('registration-form--saving')
			saver.classList.add('registration-saver--in-progress')

			const formIsValid = this.validateForm(form, false)

			if (formIsValid === 0) {
				// error + prevent submission
				form.classList.remove('registration-form--saving')
				form.classList.add('registration-form--error')
				saver.classList.remove('registration-saver--in-progress')
				return
			}

			if (formIsValid === 1) {
				// no error
				form.classList.remove('registration-form--error')
			}

			const formData = new FormData(form)
			formData.append('_is_save', true)

			fetch(form.getAttribute('action'), {
				method: 'POST',
				body: formData,
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
				},
			})
				.then(response => response.json())
				.then(data => {
					if (data.status && (data.status === 'success' || data.status === 'info')) {
						Swal.fire({
							allowOutsideClick: false,
							html: '<div class="swal2-html-inner">'
								+ '<h2>' + data.message.title + '</h2>'
								+ '<p>' + data.message.body + '</p>'
								+ (data.message.list ? '<ul>' + Object.keys(data.message.list).map(key => '<li>' + data.message.list[key] + '</li>').join('') + '</ul>' : '')
								+ '</div>',
							icon: data.status,
							confirmButtonColor: '#2A4C7C',
						})
							.then(() => {
								const firstInputWithError = form.querySelector('.registration-form-error')
								if (firstInputWithError) {
									// error + allow submission
									const contentArea = document.querySelector('.bartender-content') || null
									if ('onscrollend' in window) {
										contentArea.onscrollend = () => {
											firstInputWithError.reportValidity()
											contentArea.onscrollend = null
										}
										contentArea.scrollTo({
											top: firstInputWithError.getBoundingClientRect().top + (contentArea === null ? window.scrollY : contentArea.scrollTop) - 100,
											behavior: 'smooth',
										})
									} else {
										if (contentArea) contentArea.style.scrollBehavior = 'auto'
										contentArea.scrollTo({
											top: firstInputWithError.getBoundingClientRect().top + (contentArea === null ? window.scrollY : contentArea.scrollTop) - 100,
											behavior: 'smooth',
										})
										firstInputWithError.reportValidity()
									}
								}
							})
					} else {
						if (data.message && data.message.title) {
							Swal.fire({
								allowOutsideClick: false,
								html: '<div class="swal2-html-inner">'
									+ '<h2>' + data.message.title + '</h2>'
									+ '<p>' + data.message.body + '</p>'
									+ (data.message.list ? '<ul>' + Object.keys(data.message.list).map(key => '<li>' + data.message.list[key] + '</li>').join('') + '</ul>' : '')
									+ '</div>',
								icon: data.status,
								confirmButtonColor: '#2A4C7C',
							})
								.then(() => {
									const firstInputWithError = form.querySelector('.registration-form-error')
									if (firstInputWithError) {
										// error + allow submission
										const contentArea = document.querySelector('.bartender-content') || null
										if ('onscrollend' in window) {
											contentArea.onscrollend = () => {
												firstInputWithError.reportValidity()
												contentArea.onscrollend = null
											}
											contentArea.scrollTo({
												top: firstInputWithError.getBoundingClientRect().top + (contentArea === null ? window.scrollY : contentArea.scrollTop) - 100,
												behavior: 'smooth',
											})
										} else {
											if (contentArea) contentArea.style.scrollBehavior = 'auto'
											contentArea.scrollTo({
												top: firstInputWithError.getBoundingClientRect().top + (contentArea === null ? window.scrollY : contentArea.scrollTop) - 100,
												behavior: 'smooth',
											})
											firstInputWithError.reportValidity()
										}
									}
								})
						}
					}

					document.querySelectorAll('.registration-form__file-preview-image--remove').forEach(img => {
						img.nextElementSibling.hidden = true
						img.remove()
					})

					document.querySelectorAll('.registration-form__file-preview-image--temp').forEach(img => {
						img.classList.remove('registration-form__file-preview-image--temp')
					})

					window.registrationFormData = data.form_data
					this.populateForm(form, data.form_data)

					if (data.person_id) {
						const personIDInput = form.querySelector('[name="pid"]')
						personIDInput.value = data.person_id
						window.history.replaceState({}, '', form.getAttribute('data-base-url') + data.person_id + '/')
						document.querySelectorAll('.js-append-pid').forEach(link => {
							link.href = link.getAttribute('data-link') + '/' + data.person_id + '/'
						})
					}
				})
				.then(() => {
					form.classList.remove('registration-form--saving')
					saver.classList.remove('registration-saver--in-progress')
				})
		})
	}

	/**
	 * Initialize feature that shows alert when form may have unsaved changes
	 */
	initFormSaveAlert () {

		const form = document.querySelector('.registration-form')
		if (!form) return

		window.addEventListener('beforeunload', event => {
			if (form.classList.contains('registration-form--error')) {
				const confirmationMessage = form.getAttribute('data-confirm-message')
				if (confirmationMessage) {
					event.preventDefault()
					return confirmationMessage
				}
			}
		})
	}

	/**
	 * Require at least one selection
	 *
	 * @param {Element} context
	 */
	initRequireAtLeastOne () {
		document.querySelectorAll('.js-registration-form-require-at-least-one').forEach(input => {
			input.required = !input.checked
			input.addEventListener('change', () => {
				let groupHasSelection = false
				document.querySelectorAll('.js-registration-form-require-at-least-one[data-require-group="' + input.getAttribute('data-require-group') + '"]').forEach(groupInput => {
					if (groupInput.checked) {
						groupHasSelection = true
					}
				})
				if (groupHasSelection) {
					document.querySelectorAll('.js-registration-form-require-at-least-one[data-require-group="' + input.getAttribute('data-require-group') + '"]').forEach(groupInput => {
						groupInput.removeAttribute('required')
					})
				} else {
					document.querySelectorAll('.js-registration-form-require-at-least-one[data-require-group="' + input.getAttribute('data-require-group') + '"]').forEach(groupInput => {
						groupInput.required = true
					})
				}
			})
			input.dispatchEvent(new Event('change', {
				bubbles: true,
				cancelable: true,
			}))
		})
	}

	/**
	 * Validate values of inputs
	 */
	initValidateValues () {
		document.querySelectorAll('.js-registration-form-validate-values').forEach(form => {
			form.addEventListener('registration-form-validate-done', () => {

				// make sure that hidden fields are not submitted
				form.querySelectorAll('.js-hide').forEach(hiddenElement => {

					// check if element is input, select, or textarea
					if (hiddenElement.matches('input, select, textarea')) {
						hiddenElement.setAttribute('data-field-name', hiddenElement.name)
						hiddenElement.name = ''
						return
					}

					// if not, check if it contains any inputs, selects, or textareas
					hiddenElement.querySelectorAll('input, select, textarea').forEach(input => {
						input.setAttribute('data-field-name', input.name)
						input.name = ''
					})
				})

				const formData = new FormData(form)
				formData.append('_validate', true)

				// submit to backend for further validation
				fetch(form.getAttribute('action'), {
					method: 'POST',
					body: formData,
					headers: {
						'X-Requested-With': 'XMLHttpRequest',
					},
				})
					.then(response => response.json())
					.then(data => {
						if (data.status && (data.status === 'success' || data.status === 'info')) {
							form.querySelectorAll('[data-hide-if-valid]:not(.js-hide)').forEach(el => {
								if (el.closest('[data-validation-condition-triggered]')) return
								el.classList.add('js-hide')
								el.classList.remove('js-show')
								el.setAttribute('data-validation-condition-triggered', '')
							})
							form.querySelectorAll('[data-show-if-valid].js-hide').forEach(el => {
								if (el.closest('[data-validation-condition-triggered]')) return
								el.classList.remove('js-hide')
								el.setAttribute('data-validation-condition-triggered', '')
							})
							if (form.querySelector('[data-validation-condition-triggered]')) {
								Swal.fire({
									allowOutsideClick: false,
									html: '<div class="swal2-html-inner">'
										+ '<h2>' + data.title + '</h2>'
										+ '<p>' + data.body + '</p>'
										+ '</div>',
									icon: 'success',
									confirmButtonColor: '#2A4C7C',
								})
							} else {
								form.classList.add('js-registration-form-validate-values--validated')
							}
						} else {
							Swal.fire({
								allowOutsideClick: false,
								html: '<div class="swal2-html-inner">'
									+ '<h2>' + data.title + '</h2>'
									+ '<p>' + data.body + '</p>'
									+ '</div>',
								icon: 'error',
								confirmButtonColor: '#2A4C7C',
							})
						}
					})
					.then(() => {
						form.querySelectorAll('[data-field-name]').forEach(input => {
							input.name = input.getAttribute('data-field-name')
							input.removeAttribute('data-field-name')
						})
						if (form.classList.contains('js-registration-form-validate-values--validated')) {
							form.setAttribute('novalidate', '')
							form.submit()
						} else {
							form.querySelectorAll('[data-validation-condition-triggered]').forEach(el => {
								el.removeAttribute('data-validation-condition-triggered')
							})
							form.classList.remove('registration-form--in-progress')
							document.querySelectorAll('.site-message').forEach(el => {
								el.remove()
							})
						}
					})
			})
		})
	}

	/**
	 * Validate form
	 *
	 * @param {Element} form
	 * @param {Boolean} preventSubmitOnErrors
	 * @return {Number} 1 if valid, 0 if errors found, -1 if errors found but submit not prevented
	 */
	validateForm (form, preventSubmitOnErrors = true) {

		// clear all field errors
		form.querySelectorAll('.registration-form-error').forEach(el => {
			el.classList.remove('registration-form-error')
		})

		// make sure that all required fields are filled in, fields with pattern defined match the pattern (unless empty and not
		// required), and fields with "max" attribute are not over the limit
		let formHasErrors = false
		let formErrors = []
		form.querySelectorAll('[required], [pattern], [type="email"]').forEach(validateInput => {

			// prevent double validation of date picker
			if (validateInput.classList.contains('duet-date__input')) {
				return
			}

			// can't validate if there is no checkValidity method
			if (typeof validateInput.checkValidity !== 'function') {
				if (validateInput.nodeName == 'DUET-DATE-PICKER') {
					validateInput = validateInput.querySelector('input')
					validateInput._duet_date_picker = validateInput.closest('duet-date-picker')
				} else {
					return
				}
			}

			// skip field if it has no name
			if (!validateInput.name && (!validateInput._duet_date_picker || validateInput._duet_date_picker && !validateInput._duet_date_picker.name)) return

			// skip empty fields that are not required
			if (validateInput.value === '' && !validateInput.hasAttribute('required')) return

			// skip fields that are hidden
			if (validateInput.closest('.js-hide')) return

			// clear custom validation rules
			validateInput.setCustomValidity('')

			// custom validation rules
			let hasCustomError = false
			if (validateInput.type === 'text' && validateInput.hasAttribute('max') && parseInt(validateInput.value) > parseInt(validateInput.getAttribute('max'))) {
				validateInput.setCustomValidity(RegistrationFormLocalization.validation_error_max.replace('{max}', validateInput.getAttribute('max')))
				hasCustomError = true
			} else if (validateInput.type === 'text' && validateInput.hasAttribute('min') && parseInt(validateInput.value) < parseInt(validateInput.getAttribute('min'))) {
				validateInput.setCustomValidity(RegistrationFormLocalization.validation_error_min.replace('{min}', validateInput.getAttribute('min')))
				hasCustomError = true
			} else if (validateInput.getAttribute('data-validation-error')) {
				validateInput.setCustomValidity(validateInput.getAttribute('data-validation-error'))
				hasCustomError = true
			}

			// validate input
			if (!hasCustomError && validateInput.checkValidity()) return

			// error found, add error class and listener to remove error class when input changes
			validateInput.classList.add('registration-form-error')
			if (validateInput._duet_date_picker) {
				validateInput._errorListener = () => {
					validateInput.classList.remove('registration-form-error')
					validateInput._duet_date_picker.removeEventListener('duetChange', validateInput._errorListener)
				}
				validateInput._duet_date_picker.addEventListener('duetChange', validateInput._errorListener)
			} else {
				validateInput._errorListener = () => {
					validateInput.classList.remove('registration-form-error')
					validateInput.removeEventListener('change', validateInput._errorListener)
				}
				validateInput.addEventListener('change', validateInput._errorListener)
			}

			// set flag to indicate that errors were found
			const label = validateInput.closest('duet-date-picker')
				? validateInput.closest('duet-date-picker').previousElementSibling
				: (
					document.querySelector('[for="' + validateInput.getAttribute('id') + '"]') || validateInput.closest('label')
				)
			if (label) {
				formErrors.push(
					label.getAttribute('data-required-error') || label.innerText.trim() + ' — ' + validateInput.validationMessage
				)
			}
			formHasErrors = true
		})

		// if errors were found, bail out early and focus on first input with error
		if (formHasErrors) {
			formErrors = [...new Set(formErrors)]
			if (preventSubmitOnErrors && form.getAttribute('data-error-title')) {
				Swal.fire({
					allowOutsideClick: false,
					html: '<div class="swal2-html-inner">'
						+ '<h2>' + form.getAttribute('data-error-title') + '</h2>'
						+ '<p>' + form.getAttribute('data-error-body') + '</p>'
						+ (formErrors.length ? '<ul>' + formErrors.map(error => '<li>' + error + '</li>').join('') + '</ul>' : '')
						+ '</div>',
					icon: 'error',
					confirmButtonColor: '#2A4C7C',
				}).then(() => {
					const firstInputWithError = form.querySelector('.registration-form-error')
					const contentArea = document.querySelector('.bartender-content') || null
					if ('onscrollend' in window) {
						contentArea.onscrollend = () => {
							firstInputWithError.reportValidity()
							contentArea.onscrollend = null
						}
						contentArea.scrollTo({
							top: firstInputWithError.getBoundingClientRect().top + (contentArea === null ? window.scrollY : contentArea.scrollTop) - 100,
							behavior: 'smooth',
						})
					} else {
						if (contentArea) contentArea.style.scrollBehavior = 'auto'
						contentArea.scrollTo({
							top: firstInputWithError.getBoundingClientRect().top + (contentArea === null ? window.scrollY : contentArea.scrollTop) - 100,
							behavior: 'smooth',
						})
						firstInputWithError.reportValidity()
					}
				})
			} else if (preventSubmitOnErrors) {
				const firstInputWithError = form.querySelector('.registration-form-error')
				const contentArea = document.querySelector('.bartender-content') || null
				if ('onscrollend' in window) {
					contentArea.onscrollend = () => {
						firstInputWithError.reportValidity()
						contentArea.onscrollend = null
					}
					contentArea.scrollTo({
						top: firstInputWithError.getBoundingClientRect().top + (contentArea === null ? window.scrollY : contentArea.scrollTop) - 100,
						behavior: 'smooth',
					})
				} else {
					if (contentArea) contentArea.style.scrollBehavior = 'auto'
					contentArea.scrollTo({
						top: firstInputWithError.getBoundingClientRect().top + (contentArea === null ? window.scrollY : contentArea.scrollTop) - 100,
						behavior: 'smooth',
					})
					firstInputWithError.reportValidity()
				}
			}
			return preventSubmitOnErrors ? 0 : -1
		}

		return 1
	}

}
